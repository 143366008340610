/**
 * @todo [web-browser-port]  Remove all usages of @apply, and remove this
 *                           shim. mainly to improve performance and dev
 *                           experience.
 */
import '@webcomponents/shadycss/apply-shim.min';
import { autorun } from 'mobx';
import { LitElement, html, css, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators/custom-element.js';
import { property } from 'lit/decorators/property.js';
import application from '../store';

const RELATIVE_URI = import.meta.url.split('/').slice(0, -2).join('/');

/**
 * A shell for quéntar applets.
 *
 * @todo [web-browser-port]  Dispose autorun reactor when reloading.
 * @todo [web-browser-port]  Use a simple router to resolve graphical
 *                           interface elements.
 */
@customElement('quentar-app')
export default class QuentarApp extends LitElement {
  static styles = css`
    :host {
      display: contents;
    }

    :host > :not([active]) {
      display: none;
    }

    :host > :not(:defined)::before {
      content: url('${unsafeCSS(`${RELATIVE_URI}/assets/two-dots.svg`)}');

      place-content: center;
      display: grid;

      position: absolute;
      inset: 0;
    }
  `;

  /** @todo [quentar-elements]  Document why this configuration exists. */
  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    delegatesFocus: true,
  };

  @property({ type: String, attribute: false })
  selectedApplet;

  constructor() {
    super();

    autorun(
      async ({ name }) => {
        if (application.selectedApplet === null) {
          return console.warn(`${name}: no applet selected`);
        }
        this.selectedApplet = application.selectedApplet;
        await this.updateComplete;

        return this.ownerDocument.defaultView.customElements
          .whenDefined(`${application.selectedApplet}-applet`)
          .then(() => {
            const applet = this.shadowRoot.querySelector(
              `${application.selectedApplet}-applet`
            );
            return application.flushFirstPaintState(applet.ownerStore);
          });
      },
      { name: 'application::applet selection' }
    );
  }

  render() {
    return html`
      <psychomotor-vigilance-applet
        class="applet"
        ?active="${this.selectedApplet === 'psychomotor-vigilance'}"
      ></psychomotor-vigilance-applet>
      <academy-applet
        class="applet"
        ?active="${this.selectedApplet === 'academy'}"
      ></academy-applet>
      <emotion-differentiation-applet
        class="applet"
        ?active="${this.selectedApplet === 'emotion-differentiation'}"
      ></emotion-differentiation-applet>
      <career-benchmarker-applet
        class="applet"
        ?active="${this.selectedApplet === 'career-benchmarker'}"
      ></career-benchmarker-applet>
      <probiotic-selection-applet
        class="applet"
        ?active="${this.selectedApplet === 'probiotic-selection'}"
      ></probiotic-selection-applet>
      <iluvatar-applet
        class="applet"
        ?active="${this.selectedApplet === 'iluvatar'}"
      ></iluvatar-applet>
      <food-awareness-applet
        class="applet"
        ?active="${this.selectedApplet === 'food-awareness'}"
      ></food-awareness-applet>
      <challenger-applet
        class="applet"
        ?active="${this.selectedApplet === 'challenger'}"
      ></challenger-applet>
      <intestinal-awareness-applet
        class="applet"
        ?active="${this.selectedApplet === 'intestinal-awareness'}"
      ></intestinal-awareness-applet>
      <thought-record-applet
        class="applet"
        ?active="${this.selectedApplet === 'thought-record'}"
      ></thought-record-applet>
      <care-manager-applet
        class="applet"
        ?active="${this.selectedApplet === 'care-manager'}"
      >
        <slot slot="overlay"></slot>
      </care-manager-applet>
      <commercial-applet
        class="applet"
        ?active="${this.selectedApplet === 'commercial'}"
      ></commercial-applet>
      <trust-applet
        class="applet"
        ?active="${this.selectedApplet === 'trust'}"
      ></trust-applet>
    `;
  }
}
