import { ButtonBase } from '@material/mwc-button/mwc-button-base';

/**
 * setImmediate and globalThis polyfills should be imported through core-js and
 * babel.
 * And indeed on development environment they are used throgh core-js.
 *
 * But due do building process limitations they should be manually polyfilled
 * here until this issue https://github.com/rollup/plugins/pull/1038 is resolved
 * and the resolution is tested on our build environment.
 *
 * @todo [user-interfaces]  Test new plugin-commonjs when issue above is
 *                          resolved.
 */
import 'setimmediate/setImmediate'; // TODO [test] replace for a parameter for polyfill.io or polyfill.app
import './polyfills/global-this'; // TODO [extensions] move to @quentar/extensions package, since it will store all language extensions and utilities

// TODO [web-browser-port] document why these polyfills are needed
import './polyfills/blob.array-buffer';
// import 'construct-style-sheets-polyfill'

// TODO [web-browser-port] selectively import polyfills in application store.js
// TODO [web-browser-port] move most polyfills to the core-js/babel plugin integration
// import "https://polyfill.app/api/polyfill?features=intl.list-format"

// const orig = CSSStyleSheet.prototype.replace
// CSSStyleSheet.prototype.replace = (a) => {
//   console.log('called', a);
//   return orig;
// };

// HACK: temporary patch to fix a safari issue
//   https://github.com/material-components/material-components-web-components/issues/1720
//   Waiting for workaround:
//   https://github.com/material-components/material-components-web-components/issues/1908
console.warn(
  '[fix-me eventually] Remove temporary mwc patch when mwc-workaround is published'
);
// ButtonBase.prototype.createRenderRoot = function () {
//   return this.attachShadow({ mode: "open", delegatesFocus: false });
// };

ButtonBase.shadowRootOptions = {
  ...ButtonBase.shadowRootOptions,
  delegatesFocus: false,
};

/**
 * @fixme [applet-framework]  it probably is a good idea to not load
 *                            mwc-button on this file, perhaps on the
 *                            shell.js would be a better place. putting
 *                            mwc-button here loads material elements before
 *                            everything else
 *
 *                            Native button elements should not be skiped as
 *                            an addition to the form submission dataset when
 *                            they [have a 'name'
 *
 *
 *                            attribute]{@link https://www.w3.org/TR/html52/sec-forms.html#sec-constructing-the-form-data-set&:~:text=The%20field%20element%20is%20a%20button%20but}
 *                            but mwc-elements do not expose an name
 *                            attribute, until there as an issue for this or
 *                            a implementation decision is made any button
 *                            with a name attribute will be considered a
 *                            submitable element.
 *
 *                            An related issue is mwc element integration
 *                            with forms
 *                            {@link https://github.com/material-components/material-components-web-components/issues/289}
 *
 *                            Also
 *                            [IronForm]{@link https://github.com/PolymerElements/iron-form/blob/672120bf1f88e57ef772bfbf3a992b1439b347ff/iron-form.js#L569}
 *                            acknoleged the hardness of serializing buttons,
 *                            but choose not to handle it
 */
Object.defineProperty(ButtonBase.prototype, 'name', {
  get() {
    return this.getAttribute('name');
  },
});
Object.defineProperty(ButtonBase.prototype, 'value', {
  get() {
    return this.getAttribute('value');
  },
});

/**
 * Sometimes delivery-interface.js is not loaded by the browser, updating the delivery status here
 * allows for initial-state (bootstrap) script to confirm that the delivery mechanism has
 * successfully finished delivering.
 */
globalThis.INITIAL_STATE.delivery.status = 'finished';
