import { types, flow, getEnv } from 'mobx-state-tree';
import { environments } from './configuration';
import { createSandbox } from './sandbox';
import { HOSTNAME_TO_APPLET_CODENAME } from './constants';

/**
 * @prettierignore
 * @callback EnvironmentAugmenter A function responsible for adding features to an applet environment
 * @param  {AppletEnvironment}            current - applet runtime environment to be modified
 * @param  {import("./configuration").ApplicationStageEnvironment}  environment - applet runtime enviroment to be modified
 * @return {AppletEnvironment}
 *
 * @typedef {Object} RuntimeAppletEnvironment   it picks relevant augmenter data from the ApplicationConfiguration
 * @property {Object}  application              application layer information and configuration available to the applet
 * @property {String}  application.environment  active application release stage environment (development test staging production)
 * @property {Boolean} application.runtime      active runtime environment (node browser)
 * @property {Object}  applet                   useful applet meta-data to augmenters
 * @property {String}  applet.name              applet codename inferred from the constants file {@link https://en.wikipedia.org/wiki/Code_name}
 * @property {Boolean} runtime                  (deprecated) prefer application.runtime
 * @property {String}  environment              (deprecated) prefer application.environment
 *
 * @typedef {RuntimeAppletEnvironment} AppletEnvironment    Global infrastructure tooling for applet layer
 * @property {Object} sandbox
 *
 * @version 0.1.0
 */

/**
 * @todo [web-browser-port]  Use RuntimeEnvironment.createApplication to
 *                           instantiate this application.
 * @todo [web-browser-port]  Rename selectedApplet to selected graphical
 *                           interface.
 * @todo [web-browser-port]  rename from "Application" to
 *                           "GraphicalInterfaceDeliveryMechanism"
 */
const Application = types
  .model('Application', {
    selectedApplet: types.maybeNull(
      types.enumeration('AppletSubdomainNames', [
        ...HOSTNAME_TO_APPLET_CODENAME.values(),
      ])
    ),

    // installability, serviceWorkerRegistration and rendering properies are set
    // from the INITIAL_STATE passed to Application.create at the end of this
    // file.
    // TODO move INITIAL_STATE to INITIAL_ENVIRONMENT and forward as a
    // > second paramenter
    installability: types.frozen(),
    serviceWorkerRegistration: types.frozen(),
    rendering: types.frozen(),
  })
  .volatile(() => ({
    location: globalThis.location,
  }))
  .actions((self) => {
    function ensureCorrectBuildForEnvironment(hostname) {
      if (
        hostname.includes('dietafodmap.com.br') &&
        hostname.includes('indefini.do') &&
        !hostname.includes('shire')
      ) {
        const errorMessage =
          '<application.initialize> bad build.\n' +
          ' run gulp build:configure before deploying!';

        document.body.innerHTML = errorMessage;
        throw new Error(errorMessage);
      }
    }

    function importDevelopmentTools(codename) {
      function applyDevelopmentTools({ developableAppletStore, ...tools }) {
        if ('applet' in globalThis) {
          developableAppletStore(globalThis.applet, Object.values(tools));
        } else {
          console.warn(
            '[application::store] could not find applet store to import. Import manually throught globalThis.developableAppletStore(store)'
          );
          globalThis.developableAppletStore = developableAppletStore;
        }

        return codename;
      }
      // TODO web-dev-server do not resolve dynamic imports declared
      // in multiple lines. open an issue or update to @web/dev-server
      // TODO support loading dev tools on staging environments
      // eslint-disable-next-line prettier/prettier
      return import("@quentar/ende/components/development/application/tools.js").then(applyDevelopmentTools)
    }

    return {
      afterCreate() {
        // emotion differentiation initial-state node runtime tests are importing this file
        // TODO update files to not import application/store when runtime is node
        const { active, runtime } = environments;
        if (active === 'test' && runtime === 'node') {
          self.selectedApplet = 'emotion-differentiation';
          return;
        }

        self.initialize(globalThis);
      },

      initialize(global) {
        // Extend environment
        const { active, runtime } = environments;
        const localDefault =
          self.localItem && self.localItem.defaultSelectedApplet;

        let hostname = null;
        if (runtime !== 'node') {
          hostname = self.location.hostname;
        }

        // Do not auto-load applet on node environments
        if (active !== 'test' && runtime === 'node') {
          return false;
        }

        // Load applet
        // TODO move to detectAppletName
        switch (active) {
          case 'production': {
            const [subDomain] = hostname.split('.');
            const fromSubdomain =
              HOSTNAME_TO_APPLET_CODENAME.get(hostname) ||
              HOSTNAME_TO_APPLET_CODENAME.get(subDomain);

            return self.loadApplet(fromSubdomain);
          }
          case 'development': {
            ensureCorrectBuildForEnvironment(hostname);

            global.application = self;
            return self
              .loadApplet(
                localDefault || HOSTNAME_TO_APPLET_CODENAME.get('default')
              )
              .then(importDevelopmentTools);
          }
          case 'staging': {
            ensureCorrectBuildForEnvironment(hostname);

            global.application = self;
            const appletLoading = self.loadApplet(
              localDefault || HOSTNAME_TO_APPLET_CODENAME.get('default')
            );

            if (runtime === 'browser' && hostname === 'localhost') {
              appletLoading.then(importDevelopmentTools);
            }

            return appletLoading;
          }
          case 'test': {
            const environment = environments[active];

            // TODO DO NOT override applet globally, fabricate it!
            environment.defaults.applet = environment.defaults.applet.replace(
              '[OVERRIDE]',
              globalThis.overrideApplet
            );

            global.application = self;
            return self.loadApplet(environment.defaults.applet);
          }
          // case 'staging':
          //   globalThis.application = self;
          //   const name = HOSTNAME_TO_APPLET_CODENAME.get(subDomain) || HOSTNAME_TO_APPLET_CODENAME.get('default');
          //   self.loadApplet(name);
          //   break;
          default: {
            throw new TypeError(
              `application.initialize: Invalid environment provided: ${active}`
            );
          }
        }
      },

      /**
       * Grabs a reference for applet store and forwards all first paint state
       *
       * Rollup build generates a polyfill loader that defers all index.html
       * scripts to start loading after polyfills, so inline scripts must
       * forward any first paint state to INITIAL_STATE variable used to
       * initialize the application store.
       *
       * States received on initialization:
       * - before install prompt event.
       * - service worker registration handler.
       *
       * @param {Applet} applet  For the active applet store.
       */
      flushFirstPaintState: flow(function* flushFirstPaintState(applet) {
        if ('onFirstPaintState' in applet) {
          applet.onFirstPaintState({
            installability: self.installability,
            serviceWorkerRegistration: self.serviceWorkerRegistration,
            rendering: self.rendering,
          });
        }

        return yield Promise.resolve(true);
      }),

      // TODO [web-browser-port] Move only this method to applet-framework
      // TODO [web-browser-port] avoid knowledge of internal applet paths (import from applet/index.js)
      loadApplet: flow(function* loadApplet(codename) {
        self.selectedApplet = codename;
        yield import(`../../${codename}/index.js`);
        return codename;
      }),

      /**
       *
       */
      loadAggregate: flow(function* loadAggregate(
        name = 'aggregate',
        namespace
      ) {
        // TODO [workflow] web-dev-server do not resolve dynamic imports declared
        // in multiple lines. open an issue or update to @web/dev-server
        // eslint-disable-next-line prettier/prettier
        const module = yield import(`../../../node_modules/@quentar/ende/components/applets/${namespace}/domain/${name}.js`);

        return module.default;
      }),

      /**
       * Creates an applet environment based on infrastructure augmenters
       *
       * NOTE: until https://github.com/tc39/proposal-top-level-await passes
       * this action must be synchronous
       *
       * @function createEnvironment
       * @param {Object}                 environments  [deprecated] contents of
       *                                               the application
       *                                               configuration file.
       * @param {EnvironmentAugmenter[]} augmenters    List of quentar packages
       *                                               environment augmenters.
       * @returns {AppletEnvironment} An. Augmented environment to be used in
       *                              applets.
       * @deprecated Environment is now directed managed by environment
       *             component, see applet-framework/README.md.
       */
      createEnvironment(_, ...augmenters) {
        const [environment, stageEnvironment] = self.createRuntimeEnvironment();
        Object.assign(getEnv(self), environment);

        return augmenters.reduce(
          (augmentedEnvironment, augmenter, index, environmentAugmenters) =>
            augmenter(
              augmentedEnvironment,
              stageEnvironment,
              index,
              environmentAugmenters
            ),
          environment
        );
      },

      /**
       * @returns {RuntimeAppletEnvironment}
       * @deprecated Environment is now directed managed by environment
       *             component, see applet-framework/README.md.
       */
      createRuntimeEnvironment() {
        if (!self.selectedApplet) {
          throw new Error(
            'application.createRuntimeEnvironment: cannot create environment without selecting applet'
          );
        }

        const stageEnvironment = environments[environments.active];
        const defaults = {
          application: {
            environment: environments.active,
            runtime: environments.runtime,
            version: environments.version,
            baseURL: self.baseURL,
            getInstance() {
              return self;
            },
          },
          applet: { name: self.selectedApplet },

          /**
           * @deprecated Prefer application.runtime or
           *             application.environment.
           */
          runtime: environments.runtime,
          environment: environments.active,
        };

        const environment = {
          ...defaults,
          sandbox: createSandbox(defaults, stageEnvironment),
        };

        return [environment, stageEnvironment];
      },
    };
  })
  .views((_) => ({
    /**
     * @todo [web-browser-port]  Create a model to represent local item type.
     * @todo [persistence]       Create a local storage persistence adapter.
     */
    get localItem() {
      if (typeof localStorage === 'undefined') return null;
      const item = localStorage.getItem('quentar');
      return item === null ? null : JSON.parse(item);
    },
    // get baseURL() {
    //   if (typeof document !== "undefined") {
    //     const element = document.querySelector("base[href]");
    //     if (element) {
    //       return element.href.slice(0, -1);
    //     } else {
    //       return location.href.split("#")[0].split("?")[0];
    //     }
    //   }
    //   return null;
    // }
  }));

// Empty state and Lazy loaded environment
export default Application.create(globalThis.INITIAL_STATE, {});
