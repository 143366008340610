// TODO [polyfills] open issue on polyfill.app or polyfill.io to add Blobl.arrayBuffer method
if (!Blob.prototype.arrayBuffer) {
  Blob.prototype.arrayBuffer = async function arrayBufferPolyfill() {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function onReaderLoad({ target }) {
        resolve(target.result);
      };
      reader.onabort = function onReaderAbort({ target }) {
        reject(target.error);
      };
      reader.readAsArrayBuffer(this);
    });
  };
}
