/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */

/**
 * Some services need to be configured before analytics is ready, because
 * segment will initialize them after loading the script, so the need to
 * augment the view with the configuration options.
 */
function augmentViewConfiguredServices(view, { environment, version }) {
  view.pendo_options = {
    guides: {
      // TODO [foundation] get pendo and other service configurations from environment
      attachPoint: 'quentar-app',
    },
    ...view.pendo_options,
  };
}

function configureLoadedServices(view, { environment, version }) {
  function onUser(listener) {
    const user = view.analytics.user();

    // TODO [applet-framework] figure out why identify event fires before the
    // > first scheduled ready callback, and use only the event listener below
    if (user) {
      let storedTraits = {};
      if (view.mixpanel) {
        const { mixpanel } = view;
        storedTraits = {
          name: mixpanel.get_property('$name'),
          email: mixpanel.get_property('$email'),
        };
      }

      listener({
        ...storedTraits,
        id: user.id(),
        ...user.traits(),
      });
    }

    view.analytics.on('identify', (id, identifyTraits) =>
      listener({ id, ...identifyTraits })
    );
  }

  // TODO [analytics] open issue requesting segment (analytics.js) to forward
  // > user identify data to bugsnag, also bump the issue
  // > https://github.com/segmentio/analytics.js-integrations/issues/345 for
  // > better error support, or ditch bugsnag in favor of rollbar
  const { Bugsnag } = view;
  if (Bugsnag) {
    Bugsnag.releaseStage = environment;
    Bugsnag.notifyReleaseStages = ['production', 'development', 'staging'];
    Bugsnag.appVersion = version;

    onUser(({ id, name, email, locale }) => {
      Bugsnag.user = { id, name, email, locale };
    });
  }

  // TODO [analytics] open issue requesting segment (analytics.js) to forward
  // > user identify data to fullstory
  const { FS } = view;
  // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-API-Capturing-custom-user-properties
  if (FS) {
    onUser(({ id, name, email }) => {
      FS.setUserVars({
        uid: id,
        displayName: name,
        email,
      });
    });
  }

  // TODO [analytics] open issue requesting segment (analytics.js) to forward
  // > user identify data to Rollbar
  const { Rollbar } = view;
  if (Rollbar) {
    const services = {
      fullstorySession: view?.FS?.getCurrentSessionURL(),
    };

    Rollbar.configure({
      // https://docs.rollbar.com/docs/rollbarjs-configuration-reference#payload-1
      payload: {
        environment,
        client: {
          javascript: {
            code_version: version,
            source_map_enabled: true,
          },
        },
        services,
      },
    });

    onUser(({ id, name, email }) => {
      Rollbar.configure({
        // https://docs.rollbar.com/docs/rollbarjs-configuration-reference#payload-1
        payload: {
          person: {
            id,
            username: name,
            email,
          },
        },
      });
    });
  }
}

function createScript(writeKey, { environment, version }) {
  /**
   * Reference snippet at [Quentar Production Destination]{@link https://app.segment.com/indefinido/sources/quentar_production/overview}
   * TODO [user-interfaces] remember to update lib/web-browser-port/initial-state.js when updating this snippet
   */
  !(function executeAnalyticsSnippet() {
    const analytics = (window.analytics = window.analytics || []);

    if (!analytics.initialize) {
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware',
        ];
        analytics.factory = function (e) {
          return function () {
            const t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (let e = 0; e < analytics.methods.length; e++) {
          const key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key, e) {
          const t = document.createElement('script');
          t.type = 'text/javascript';
          t.async = !0;
          t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
          const n = document.querySelectorAll('script')[0];
          n.parentNode.insertBefore(t, n);
          analytics._loadOptions = e;
        };
        analytics._writeKey = writeKey;
        analytics.SNIPPET_VERSION = '4.13.2';
        analytics.load(writeKey);
        analytics.page();
      }
    }
  })();

  const { analytics } = window;

  // TODO [applet-framework] move to analyzable-applet and only in development
  // > and staging environments
  const timeout = setTimeout(() => {
    let message =
      '[monitoring::services] segment failed to emit ready event after 30 seconds.';
    message +=
      '\n Check for new integration incompatibilities or configuration failure';

    if (window.analytics && window.analytics.Integrations) {
      Object.entries(window.analytics.Integrations).forEach(
        ([
          name,
          integration = {
            name,
            loaded() {
              return false;
            },
          },
        ]) => {
          message += `\n Integration: ${
            name || integration.name
          } Loaded? ${integration.loaded?.()}`;
        }
      );
    }
    console.error(message);
  }, 30 * 1000);

  augmentViewConfiguredServices(window, { environment, version });

  // TODO [applet-framework] figure out why identify event fires before the
  // > first scheduled ready callback (the one written below)
  analytics.ready(() => {
    clearTimeout(timeout);
    configureLoadedServices(window, { environment, version });
  });
}

export function load({ key, ...context }) {
  return createScript(key, context);
}
