/**
 * Holds the subdomain mappings for applets
 *
 * Map:
 * - subdomain ➜ applet folder.
 *
 * @type {Map}
 * @todo [ende]  [web-browser-port] resolve issues on comment
 *               https://github.com/indefinido/quentar/pull/20#discussion_r413392893
 * @todo [ende]  [web-browser-port] move this information to a configuration
 *               file on the port or somewhere near it.
 * @todo [ende]  [web-browser-port] wait until firebase supports es6 imports
 *               or update the build process to compile this file into a node
 *               compatible environment. until there remember to update
 *               ../functions/application/constants when updating this file.
 */
// eslint-disable lines-around-comment
export const HOSTNAME_TO_APPLET_CODENAME = new Map([
  // production hostnames
  ['dietafodmap.com.br', 'commercial'],
  ['becare.life', 'care-manager'],

  // production subdomains
  ['reaction', 'psychomotor-vigilance'],
  ['academy', 'academy'],
  ['emotion', 'emotion-differentiation'],
  ['career', 'career-benchmarker'],
  ['probiotic', 'probiotic-selection'],
  ['challenge', 'challenger'],
  ['iluvatar', 'iluvatar'],
  ['coali', 'food-awareness'],
  ['rp', 'thought-record'],
  ['numero2', 'intestinal-awareness'],
  ['giver', 'care-manager'],
  ['commercial', 'commercial'],
  ['trusted', 'trust'],

  // development | remote staging - yarn start / yarn deploy:staging
  ['default', 'care-manager'],

  // yarn serve:development:* (localhost development)
  ['192', 'iluvatar'],

  // @todo yarn serve:staging:* (local/localhosted staging)
  ['127', 'emotion-differentiation'],

  // Shire codename is used on server rendering of shire :
  // yarn deploy:staging:functions (remote staging)
  ['shire', 'care-manager'],
]);
// eslint-enable lines-around-comment
