// TODO [monitoring] refactor architecture of this and services/segment and move
// > them to monitoring/presentation/primary-adapters/web-browser/initial-state.js
import * as segment from './services/segment';

export default function startup(environments) {
  const { keys } = environments[environments.active];

  const configuration = {
    key: keys.get('segment'),
    version: environments.version,
    environment: environments.active,
  };

  return keys.has('segment') ? segment.load(configuration) : null;
}
