// TODO [applet-framework] [environment] move all public keys, identifiers and configurations to the shared-kernel/public element
import version from './version';

/**
 * @typedef {import('@quentar/ende/shared-kernel/configuration/private').ApplicationStageIdentifier}
 * ApplicationStageIdentifier
 */

/**
 * Active application stage, signifies mainly how infrastructure should be
 * loaded.
 *
 * @typedef {Object} ApplicationStageEnvironment
 * @typedef {Object} ApplicationConfiguration
 * @property {Map<string, string>}         keys
 * @property {Map<string, string>}         identifiers
 * @property {Map<string, string>}         domains
 * @property {Map<string, string>}         urls
 * @property {string | '4.2.0'}            version      - defined at build time
 *                                                      or 4.2.0 in development
 *                                                      stages.
 * @property {ApplicationStageIdentifier}  active       - detected active
 *                                                      release stage.
 * @property {ApplicationStageEnvironment} test
 * @property {ApplicationStageEnvironment} development  - environment
 *                                                      configuration for the
 *                                                      development stage.
 * @property {ApplicationStageEnvironment} staging
 * @property {ApplicationStageEnvironment} production
 */

/**
 * Active application stage, signifies mainly how infrastructure should be
 * loaded
 *
 * NOTE:
 * The value of this variable is replaced on build time
 *
 * NOTE:
 * Due to a limitation on MobxStateTree module version, process.env.NODE_ENV
 * must be defined on browser environment. It is defined on
 * node-process-mocks.js import this mocks before this configuration file.
 *
 * They do not provide a module version for production environments.
 *
 * @type {ApplicationStageIdentifier}
 * @see {@link https://github.com/mobxjs/mobx-state-tree/blob/master/packages/mobx-state-tree/rollup.config.js}
 *      for reference
 */

const active = process.env.NODE_ENV;
// const active = "staging"; // See get staging() docs for information on when to use this value as active environment

function getOrigin() {
  const { location } = globalThis;
  const names = location.hostname.split('.');
  let subDomain;
  let domain;

  switch (names.length) {
    case 2: {
      domain = names.join('.');
      break;
    }

    default: {
      [subDomain, ...domain] = names;
      domain = domain.join('.');
      break;
    }
  }

  return location
    ? {
        hostname: location.hostname,
        port: location.port,
        protocol: location.protocol,
        subDomain,
        domain,
      }
    : undefined;
}

// https://gist.github.com/odyniec/4590546
function getUserAgentDescription() {
  const matches = navigator.userAgent.match(/Version\/(\S+).*?Safari\//);
  if (matches) {
    return {
      browser: 'safari',
      version: Number.parseFloat(matches[1]),
    };
  }
  return {
    browser: 'other',
    version: 'unknown',
  };
}

// eslint-disable-next-line no-new-func
// TODO [configuration] move runtime detection and definition to whoever uses the configuration
const isBrowser = new Function(
  'try {return this === window;} catch(e) { return false;}'
);

/**
 * While attempting to use
 * {@link https://firebase.google.com/docs/auth/web/redirect-best-practices#update-authdomain
 * mitigation # 1},
 * seeing {@link https://www.youtube.com/watch?v=VVgtGkRtVPU privacy sandbox
 * updates}
 * and doing some testing Heitor concluded that firebase authentication is
 * throwing an error when using the authentication sub-domain instead of using a
 * sub-domain that matches the web-browser current url.
 *
 * @see https://github.com/firebase/firebase-js-sdk/issues/6716 for why Login
 *      to Firebase does not work on Safari 16.1+
 * @todo [web-browser-port]  [authentication] wait for issue
 *                           https://github.com/firebase/firebase-js-sdk/issues/6716
 *                           to be closed and define this as a more permanent
 *                           solution
 */
function hasSessionStoragePartitioningBetweenSubdomains() {
  if (!isBrowser) return;
  const description = getUserAgentDescription();
  return !(description.browser === 'safari' && description.version > 16);
}

/**
 * @type {ApplicationConfiguration}
 * @exports
 * @todo [applet-framework]  [environment] move all keys, identifiers and
 *                           configurations to a configuration port or a
 *                           shared-kernel element.
 */
// eslint-disable-next-line import/prefer-default-export
export const environments = {
  version,

  active,

  // TODO [configuration] move runtime detection and definition to whoever uses the configuration
  runtime: isBrowser() ? 'browser' : 'node',

  test: {
    defaults: {
      /**
       * Default test applet configuration when sub-domain is not available it
       * is overwritten by quentar-testing to test multiple applets
       * simultaneously
       *
       * see packages/application/src/test/plugins/puppeteer/quentar-testing.js.
       *
       * @type {string}
       */
      applet: '[OVERRIDE]',
    },

    keys: new Map([
      ['segment', 'ZKpBN4kQNUbYa8z6OZOmokmYqhnYMqO6'],
      ['firebase', 'AIzaSyD56Ola3kuBNKFFZ3Phs_9z2elPOMLMMuM'],
      ['google-maps', 'AIzaSyBixui0CH_NimR-AuglxuhHokUTJFEPYf0'],
    ]),
    identifiers: new Map([
      // TODO ['algolia', 'D34DLNKY5X'],
      ['firebase', 'quen-tar'],
      [
        'firebase-application-identifier',
        '1:303447132639:web:4b50243326297f7a261c6a',
      ],
      // TODO ['messaging', '303447132639'],
      // TODO should we ['bucket', 'quentar-test'] ?,
      // TODO ["identity-pool", "us-east-1:c35456e7-c98c-46c6-a83e-f8b24ecae77e"]
    ]),
    domains: new Map([
      // TODO [test] create test environment segment key?
      ['authentication', '127.0.0.1'],
      // ['bucket', 'quen-tar.appspot.com']
    ]),
    urls: new Map([
      ['real-time-database', 'http://localhost:9000?ns=quen-tar'],
      ['firestore', `http://localhost:9000?ns=quen-tar`],
    ]),
  },

  development: {
    keys: new Map([
      // TODO implement dynamic activation of chat in development environment?
      ['tawk-to', '2be4754ee87a3c5f948720a4fe30bc4fe9a0118c'],
      ['segment', 'ZKpBN4kQNUbYa8z6OZOmokmYqhnYMqO6'],
      ['algolia', 'c7269b2cbcd5b3c8dbd433891e890d25'], // api_key
      ['firebase', 'AIzaSyD56Ola3kuBNKFFZ3Phs_9z2elPOMLMMuM'],
      ['clickup', 'pk_467923_NYISNYRCMJ0KH6L72D69CRYJC909YNNT'], // api_token
      [
        'web-push-certificate',
        'BEeOT9ZX7NvY2BvmPoXoFPMuVbpCNJ3sxDr415VxziYJHaPLVeUOS0cYfCudqWO9rMiOj9kxY8RICfvZC57fcgQ',
      ],
      ['stripe', 'pk_test_WcmokYaminkNmfJBCGOmB1vE00obLyMdsC'], // api_key
      ['google-maps', 'AIzaSyBixui0CH_NimR-AuglxuhHokUTJFEPYf0'],
    ]),
    identifiers: new Map([
      // TODO implement dynamic activation of chat in development environment?
      ['tawk-to', '5c470203ab5284048d0e03c6'],
      ['algolia', 'D34DLNKY5X'],
      ['firebase', 'quen-tar'],
      [
        'firebase-application-identifier',
        '1:303447132639:web:4b50243326297f7a261c6a',
      ],
      ['messaging', '303447132639'],
      ['bucket', 'quentar-development'],
      ['identity-pool', 'us-east-1:c35456e7-c98c-46c6-a83e-f8b24ecae77e'],
      ['wirecard', 'heitorsalazar@gmail.com'],
    ]),
    domains: new Map([
      ['authentication', `${getOrigin().hostname}`],
      ['storage', 's3.amazonaws.com'],
    ]),
    urls: new Map([
      [
        'real-time-database',
        `${getOrigin().protocol}//${getOrigin().hostname}:9000?ns=quen-tar"`,
      ],
      [
        'firestore',
        `${getOrigin().protocol}//${getOrigin().hostname}:9000?ns=quen-tar`,
        // `https://33ff-2804-14c-5b81-57e6-e1ef-4fb1-ecf2-a688.ngrok-free.app?ns=quen-tar`,
      ],
      [
        'wirecard',
        `https://desenvolvedor.moip.com.br/sandbox/PagamentoMoIP.do`,
      ],
      // ["wirecard", `https://www.moip.com.br/PagamentoMoIP.do`]
    ]),
  },

  /**
   * By default the staging environment gets selected when the code is published
   * to shire.indefini.do.
   *
   * The exceptions to this case are:
   *
   * • When locally trying to test a production build (through firebase
   * emulator), the staging environment configuration gets selected so the
   * locally available authentication service should be used thus the override
   * for development configuration in the beginning of this getter function.
   *
   * • When locally trying to test firebase extensions (eg. stripe), the staging
   * environment configuration should be manually set on the top of this file
   * and the override should be commented.
   *
   * @type {ApplicationStageEnvironment}
   * @todo [web-browser-port]  implement [Emulator suite support for firebase
   *                           extensions]{@link https://github.com/firebase/firebase-tools/issues/3405}
   *                           when issue is closed, and remove the
   *                           configuration override
   */
  get staging() {
    // See function docs above to understand this override
    if (
      getOrigin().hostname === 'localhost' ||
      getOrigin().hostname === '192.168.0.84'
    )
      return this.development;

    return {
      ...this.development,
      keys: new Map([
        ...this.development.keys,
        ['firebase', 'AIzaSyALwClH1cG6GT93YT8gma_YEOPiQWhh_mo'],
      ]),
      identifiers: new Map([
        ...this.development.identifiers,
        ['firebase', 'shi-re'],
        [
          'firebase-application-identifier',
          '1:347430912685:web:a15b87f03336e2def37c24',
        ],
        ['firebase-measurement-identifier', 'G-CYH7BTCKKJ'],

        // TODO [storage] create staging bucket
        ['bucket', 'quentar-development'],
        ['messaging', '347430912685'],
      ]),
      domains: new Map([
        ...this.development.domains,
        [
          'authentication',

          hasSessionStoragePartitioningBetweenSubdomains()
            ? `authentication.${getOrigin().domain}`
            : getOrigin().hostname,
        ],
      ]),
      urls: new Map([
        ...this.development.urls,
        ['firestore', 'https://firestore.googleapis.com'],
        ['real-time-database', 'https://shi-re.firebaseio.com'],
      ]),
    };
  },

  get production() {
    /**
     * When firebase hosting is running locally and a production build was recently built locally the active stage
     * will be the 'production' stage. This check below is a poka-yoke that prevents sending bad analytics data to
     * all production tools and keys when accessed locally (probably accidentally) by a developer.
     */
    if (getOrigin().hostname === 'localhost') {
      throw new TypeError(
        `[web-browser-port/configuration.js] Bad build configuration: production environment is should not be used locally`
      );
    }

    return {
      keys: new Map([
        ['tawk-to', '2be4754ee87a3c5f948720a4fe30bc4fe9a0118c'],
        ['session-stack', '6cdab862381f4d5da5e89c591d7b33aa'],
        ['segment', 'bL3mhqQwF8UKb1wOqplvEuUBUipk5JGc'],
        ['algolia', 'c7269b2cbcd5b3c8dbd433891e890d25'], // api_key
        ['firebase', 'AIzaSyD56Ola3kuBNKFFZ3Phs_9z2elPOMLMMuM'],
        ['clickup', 'pk_467923_NYISNYRCMJ0KH6L72D69CRYJC909YNNT'],
        [
          // api_token
          'web-push-certificate',
          'BCdTtGMiJ2dAmqcBOhYooZ396dwh-YT5zNDPx6EhQq0zzFayQXviXglTXhwBYwtiVYQAwPIfdMc2SJTWGVfD1nw',
        ],
        ['stripe', 'pk_live_TH0Rie3IRC8hKcMqjEAolw2400SWLuqJte'], // api_key
        ['google-maps', 'AIzaSyAOZ78iDS31-mu8TLvzKVGiHceE9v78SMs'],
      ]),
      identifiers: new Map([
        ['tawk-to', '5c470203ab5284048d0e03c6'],
        ['algolia', 'D34DLNKY5X'],
        ['firebase', 'quen-tar'],
        [
          'firebase-application-identifier',
          '1:303447132639:web:4b50243326297f7a261c6a',
        ],
        ['messaging', '303447132639'],
        ['bucket', 'quentar'],
        ['identity-pool', 'us-east-1:db01b983-d619-4825-8693-f031e8b0fbd7'],
        ['wirecard', 'heitorsalazar@gmail.com'],
      ]),
      domains: new Map([
        [
          'authentication',

          hasSessionStoragePartitioningBetweenSubdomains()
            ? `authentication.${getOrigin().domain}`
            : getOrigin().hostname,
        ],
        ['bucket', 'quen-tar.appspot.com'],
        ['storage', 's3.amazonaws.com'],
      ]),
      urls: new Map([
        ['real-time-database', 'https://quen-tar.firebaseio.com'],
        ['wirecard', `https://www.moip.com.br/PagamentoMoIP.do`],
      ]),
    };
  },
};
