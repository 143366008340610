import { observable, reaction, runInAction } from "mobx";
import { fromPromise, FULFILLED } from "mobx-utils";

/**
 * Sandbox is required due to polymer builder and rollup builder limitations
 */
export function createSandbox({ environment }) {
  const loadings = observable.map([], { deep: false });
  const readings = new Map();

  function createThenable(module) {
    if ("then" in module) return module;
    const source =
      typeof module === "function" ? module.bind(module) : { ...module };
    return Object.assign(
      source,
      {
        then(onFulfilled) {
          return Promise.resolve(onFulfilled(module));
        },
        loaded: true,
      },
      module
    );
  }

  /**
   * Loads the javascript at the given url through injecting a script tag on the
   * head element
   *
   * @todo remove global document reference o
   *
   * @param  {URL|String} url the url of the javascript file
   *
   * @return {Promise}      a promise that resolves to the load or error event.
   */
  function loadScript(url) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = url;
      script.addEventListener("load", resolve);
      script.addEventListener("error", reject);
      document.head.appendChild(script);
    });
  }

  function createThenableLoadObservable(name, loader) {
    return function reactor() {
      const isLoading = loadings.get(name);

      if (readings.has(name)) {
        return readings.get(name);
      }
      if (isLoading) {
        return loadings.get(name);
      }

      const loading = loader(readings, loadings);

      readings.set(
        name,
        observable({
          loaded: false,
          then: loading.then.bind(loading),
        })
      );

      return readings.get(name);
    };
  }

  reaction(
    () => {
      return [
        loadings,
        Array.from(loadings.values()).filter(({ state }) => state),
      ];
    },
    ([loadings]) => {
      loadings.forEach(({ state, urls, value }, name) => {
        switch (state) {
          case "initialize": {
            const modules = [];

            const chain = urls.reduce((promise, url) => {
              return promise
                .then(() => url)
                .then((module) => {
                  modules.push(module);
                  return module;
                });
            }, Promise.resolve());
            runInAction(() =>
              loadings.set(name, fromPromise(chain.then(() => modules[0])))
            );
            break;
          }
          // case PENDING:
          //   break;
          case FULFILLED: {
            const module = value.default;
            if ("then" in value.default) {
              console.error(
                "[sandbox#createSandbox] module already have then, overriding!"
              );
            }

            const reading = readings.get(name);
            reading.loaded = true;
            readings.delete(name);

            const thenable = createThenable(module);
            thenable.loaded = true;
            runInAction(() => loadings.set(name, thenable));
            reading.resolve(thenable);
            break;
          }
          default:
            break;
        }
      });
    },
    {}
  );

  const momentReactor = createThenableLoadObservable(
    "moment",
    (readings, loadings) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          Object.assign(readings.get("moment"), { resolve, reject });

          const promise = import("moment/moment.js").then((moment) => {
            // TODO learn how to avoid this promise nesting
            // eslint-disable-next-line promise/no-nesting
            return import("moment/locale/pt-br.js").then(() => moment);
          });
          runInAction(() => {
            loadings.set("moment", {
              state: "initialize",
              // TODO remove this array
              urls: [promise],
            });
          });
        });
      })
  );

  const plotlyReactor = createThenableLoadObservable(
    "plotly",
    (readings, loadings) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          Object.assign(readings.get("plotly"), { resolve, reject });

          runInAction(() => {
            loadings.set("plotly", {
              state: "initialize",
              // TODO remove this array
              urls: [
                loadScript(
                  environment === "development"
                    ? "https://cdn.plot.ly/plotly-1.58.4.js"
                    : "https://cdn.plot.ly/plotly-1.58.4.min.js"
                )
                  .then(() =>
                    loadScript(
                      "https://cdn.plot.ly/plotly-locale-pt-br-latest.js"
                    )
                  )
                  .then(() => Plotly.setPlotConfig({ locale: "pt-BR" }))
                  .then(() => ({ default: window.Plotly })),
              ],
            });
          });
        });
      })
  );

  return observable({
    get moment() {
      return momentReactor();
    },
    get plotly() {
      return plotlyReactor();
    },
  });
}
