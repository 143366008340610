/**
 * @todo [ende] environments configuration to a configuration architectural
 *              component, and require it inside the monitoring services and
 *              expose initial state from a port. also consider having a
 *              these contents at the web-browser delivery mechanism. after
 *              that remove this file and require startup from
 *              @quentar/ende/components/monitoring/services and just run it.
 */
import startup from "@quentar/ende/components/monitoring/services";
import { environments } from "../configuration";

const { rendering } = globalThis.INITIAL_STATE;
/**
 * Since the prerender service runs a google chrome that renders the page as a
 * user would this check prevents tracking non-user activity as user activity
 */
if (rendering.mode !== "headless") {
  // eslint-disable-next-line mocha/no-top-level-hooks, mocha/no-hooks-for-single-case
  startup(environments);
}
